import { Accordion, AccordionDetails, AccordionSummary } from '@achieve/ascend'
import { Typography } from 'components/Contentful'
import { ChevronDown } from 'react-feather'
import styles from './SectionAccordion.module.scss'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'
import classNames from 'classnames'

const SectionAccordion = ({ section, title, sectionNumber, selected, onChangeExpanded }) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const detailClass = selected
    ? styles['accordion-detail']
    : `${styles['accordion-detail']} ${styles['hidden-content']}`

  return (
    <TrackingWrapper
      track={{
        list_name: 'SECTION ACCORDION EXPANDED',
        click_id: !(selected === sectionNumber) ? 'Open' : 'Close',
        click_position: sectionNumber,
        click_text: title,
      }}
    >
      <Accordion
        className={styles['accordion']}
        data-testid="section-accordion"
        expanded={selected}
        id={`sectionAccordion-${sectionNumber}`}
        key={sectionNumber}
        onChange={onChangeExpanded}
      >
        <AccordionSummary
          className={styles['accordion-summary']}
          data-testid="accordion-title"
          expandIcon={
            <ChevronDown
              className={selected === sectionNumber ? styles['expand-icon-selected'] : ''}
              size={isMobile ? 24 : 32}
            />
          }
        >
          <Typography
            className={classNames(
              styles['accordion-summary-text'],
              'bodyLg lg:headingMd font-medium'
            )}
            content={title}
            component="h2"
          />
        </AccordionSummary>
        <AccordionDetails className={detailClass} data-testid="accordion-section">
          {section}
        </AccordionDetails>
      </Accordion>
    </TrackingWrapper>
  )
}

export { SectionAccordion }
